// jshint ignore: start
import React, {useState, useEffect} from "react";
import { graphql, useStaticQuery, navigate } from "gatsby";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Fade } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import json2mq from 'json2mq';
import MenuIcon from '@material-ui/icons/Menu';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { GatsbyImage } from "gatsby-plugin-image"

const pages = [
  ["Via Espiga","viaespiga"],
  ["Marcas","marcas"],
  ["Fotos","fotos"],
  ["Contactos","contactos"]
]

export function useWindowPosition() {
  const [scrollPosition, setPosition] = useState(0);
  useEffect(() => {
    function updatePosition() {
      setPosition(window.pageYOffset);
    }
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);
  return scrollPosition;
}

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    appbar: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: 'white',
      transform: 'translateZ(0)',
      transition: 'opacity .5s ease-out'
    },
    appbar2: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: 'transparent',
      transform: 'translateZ(0)',
      transition: 'opacity .5s ease-out'
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 0,
      padding: 0,
      width: '80px',
      marginTop: '4px',
      maxHeight: '70px',
      marginRight: 'auto',
    },
    ya: {
      borderColor: '#fff',
    },
    ya2: {
      borderColor: '#000',
    },
    gutters: {
      padding: '0 36px'
    },
    button: {
      margin: theme.spacing(1)
    },
    toggle: {
      padding: 5
    },
    appMenu: {
        width: '100%',
        paddingTop: 64,
        margin: '0 auto'
      },
      navList: {
        width: '100%',
      },
      menuItem: {
        width: '100%',
      },
      menuItemIcon: {
        color: '#97c05c',
      },
}));

const Header = ({ children }) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const position = useWindowPosition();
  const is815 = useMediaQuery(
    json2mq({
      maxWidth: 815,
    }),
  );
  const is900 = useMediaQuery(
    json2mq({
      maxWidth: 900,
    }),
  );

  const data = useStaticQuery(graphql`
    query {
      loguinho: file(relativePath: { eq: "site-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 90, placeholder: NONE)
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const handleHome = () => {
    navigate('/')
  }

  const handleClickMenu = (e) => {
    navigate("/" + e.currentTarget.id)
  }

    return (
      <div>
          <Fade timeout={1000} in={position > 530 ? true : false}>
            <AppBar className={classes.appbar} position="sticky" elevation={0}>
              <Toolbar variant="regular" classes={{gutters: classes.gutters}}>
                <div className={classes.title}>
                  <GatsbyImage alt="" loading="eager" image={data.loguinho.childImageSharp.gatsbyImageData} />
                </div>
                {/* <div style={{color: "red"}}>{data.site.siteMetadata.title}</div> */}
              </Toolbar>
            </AppBar>
          </Fade>
          <Fade timeout={1000} in={position > 530 ? true : false}>
          <AppBar className={classes.appbar2} position="sticky" elevation={0}>
            <Toolbar variant="regular" style={{alignSelf: 'flex-end'}} classes={{gutters: classes.gutters}}>
              {!is900 && <div>{pages.map((p, i) => <Button key={i} onClick={handleClickMenu} classes={{root: classes.ya2}} style={!is815 ? {margin: 8, color: 'black'} : {margin: 8, color: 'black', background: 'rgba(0, 0, 0, .25)'}} variant="outlined" color="default" id={p[1]} disableTouchRipple href="#">{p[0]}</Button>)}</div>}
              {is900 &&  <ToggleButton classes={{'root': classes.toggle}} value="check" selected={menuOpen} onChange={() => { window.scrollTo(0, 0); setMenuOpen(!menuOpen); }}><MenuIcon/></ToggleButton>}
            </Toolbar>
            <div role="button" aria-label="Click" tabIndex={0} onClick={handleHome} onKeyDown={handleHome} style={{position: 'absolute', top: 10, left: 36, width: 90, height: 40, cursor: 'pointer'}}></div>
          </AppBar>
          </Fade>
          <Fade timeout={1000} in={position > 530 ? false : true}>
            <AppBar className={classes.appbar2} position="sticky" elevation={0}>
            <Toolbar variant="regular" style={{alignSelf: 'flex-end'}} classes={{gutters: classes.gutters}}>
              {!is900 && <div>{pages.map((p, i) => <Button key={i} onClick={handleClickMenu} classes={{root: classes.ya}} style={!is815 ? {margin: 8, color: 'white'} : {margin: 8, color: 'white', background: 'rgba(0, 0, 0, .25)'}} variant="outlined" color="default" id={p[1]} disableTouchRipple>{p[0]}</Button>)}</div>}
              {is900 &&  <ToggleButton style={{color: 'white'}} classes={{'root': classes.toggle}} value="check" selected={menuOpen} onChange={() => { window.scrollTo(0, 0); setMenuOpen(!menuOpen); }}><MenuIcon/></ToggleButton>}</Toolbar>
            </AppBar>
          </Fade>
          <Collapse in={menuOpen}>
            <Paper elevation={4} className={classes.paper} style={{position: 'relative', top: 0, left: 0, right: 0, zIndex: 999}}>
              <div style={{height: 'auto', background: 'white', width: '100%'}}>
                <List component="nav" className={classes.appMenu} disablePadding>
                  <ListItem button onClick={handleHome} className={classes.menuItem}>
                    <ListItemText primary="Início" />
                  </ListItem>
                  {pages.map((p, i) => (
                    <ListItem key={i} button onClick={handleClickMenu} id={p[1]} className={classes.menuItem}>
                      <ListItemText primary={p[0]} />
                    </ListItem>
                  ))}
                </List>
              </div>
            </Paper>
        </Collapse>
      </div>
    )
}

export default Header